.container {
  width: fit-content;
}

img {
  object-fit: cover;
}

div.icon {
  display: flex;
}

div.icon #good {
  margin: auto;
  fill: #67bc0a;
}

div.icon #bad {
  margin: auto;
  fill: #f95252;
}
