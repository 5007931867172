/* The animation code */
@keyframes stretch-a {
  0%   {height: 83%}
  5%   {height: 83%}
  26%  {height: 21%}
  47%  {height: 83%}
  52%  {height: 83%}
  63%  {height: 50%}
  74%  {height: 83%}
  89%  {height: 38%}
  100% {height: 83%}
}

@keyframes stretch-b {
  0%   {height: 35%}
  10%  {height: 35%}
  60%  {height: 88%}
  100% {height: 35%}
}

@keyframes bounce-ball {
  0%   {transform: translateY(400%);}
  30%  {transform: translateY(400%);}
  50%  {transform: translateY(80%);}
  80%  {transform: translateY(80%);}
  100% {transform: translateY(400%);}
}

.padded {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

#logo {
  height: 20vmin;
  width: 20vmin;
  display: flex;
}

.brand-a {
  align-self: flex-end;
  margin-left: auto;
  width: 20%;
  height: 83vh;
  background: #fffffe;
  border-radius: 25px;

  animation-name: stretch-a;
  animation-duration: 5600ms;
  animation-iteration-count: infinite;
}

.brand-b {
  align-self: flex-end;
  width: 20%;
  height: 35%;
  background: #fffffe;
  border-radius: 25px;

  animation-name: stretch-b;
  animation-duration: 2700ms;
  animation-iteration-count: infinite;
}

.brand-c {
  position: relative;
  margin-right: auto;
  width: 20%;
  height: 20%;
  background: #89C23C;
  border-radius: 50%;

  animation-name: bounce-ball;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
