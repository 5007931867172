.component {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

div.recog-container {
  flex: 1;
  position: relative;
  height: 100%;
}

div.recog-container .below {
  position: absolute;
  bottom: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #21366C;
  padding-bottom: 0.5rem;
}

div.recog-container div#webcam {
  position: relative;
  display: flex;
  background-color: rgb(31, 29, 29);
  height: calc(100% - 90px);
  width: 100%;
}

div.recog-container div#stillframe {
  height: calc(100% - 140px);
  display: flex;
  position: relative;
  width: 100%;
}


.below.confirm {
  height: 140px;
}

.below.live {
  min-height: 80px;
}

.webcam-actions {
  flex-direction: column;
  display: flex;  
}
