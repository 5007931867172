main {
  align-items: center;
  justify-content: center;
  background-color: #21366C;
  height: 100%;
  width: 100%;
  overflow: auto;
  position: fixed;
}

.pageContainer {
  display: block;
  width: 100%;
  height: 100vh;
  align-content: space-around;
}

div.centeredPage {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;

  width: 100%;
  height: 100%;
  align-content: center;
  place-content: center;
  overflow: clip;
}

.scrollablePage {
  height: 100%;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  place-content: center;
}

div.pageContent {
  padding-block-start: 1rem;
  padding-block-end: 1rem;
  min-height: 20vh;
}

div.centeredContent {
  margin: auto;
}

div.webcam-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

div.actions {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: fit-content;
  padding-inline: 0 1rem 0 1rem;
}

div.actions>* {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

img.logo.heading {
  object-fit: 'contain';
  max-height: 100;
  margin: 0 auto 2rem;
  max-width: 100%;
  text-align: center;
}

div.images-horizontal {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.images-horizontal * {
  margin: auto;  
}
